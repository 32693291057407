import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  private counterFormat: string = '';
  private headerText : string = '';
  public show: boolean = false;
  private closeStatus: number;
  private steperStatus: number = 1;
  constructor(
    
  ) {

   }

  public setCounterFormat(value) {
    this.counterFormat = value;
  }

  public setHeaderText(text: string) {
    this.headerText = text;
  }

  public getCounterFormat() {
    return this.counterFormat;
  }

  public getHeaderText() {
    return this.headerText;
  }

  public initTimer(counterFormat,headerText) {
    this.show = true;
    this.setCounterFormat(counterFormat);
    this.setHeaderText(headerText);
  }

  public stopTimer() {
    this.show = false;
    this.setCounterFormat('');
    this.setHeaderText('');
  }

  public getCloseStatus() {
    return this.closeStatus;
  }

  public setCloseStatus(status: number) {
    this.closeStatus = status;
  }

  public getSteperStatus() {
    return this.steperStatus;
  }

  public setSteperStatus(status: number) {
    this.steperStatus = status;
  }

  public restartSteperStatus() {
    this.steperStatus = null;
  }

}
