import { Component, OnInit } from '@angular/core';
import { DashboardService } from '@core/services/resources/dashboard.service';
import { SvgRegisterService } from '@core/material/svg-register.service';
import { AuthService } from '@core/services/users/auth.service';
import { Observable, Observer } from 'rxjs';
import { share } from 'rxjs/operators';
import { User } from '@core/interfaces/user';
import { NavigationEnd, Router } from '@angular/router';
import { OneSignalService } from '@core/services/one-signal/one-signal.service';
//import { ModalConfirmOpeComponent } from './features/auction/modal-confirm-ope/modal-confirm-ope.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public observable: Observable<boolean>;
  private observer: Observer<boolean>;
  userCompany: string; 
  user: User ;
  menuSubBlock = false;
  profileUrl: string;
  nameUser: string;
  acronimo: string;
  isListeningSubscriptionState = false;
  isListeningPermissionState = false;
  isLoggedIn = false;
  
  constructor(
    public _dashboardService: DashboardService,
    private _svgRegisterService: SvgRegisterService,
    private _authService: AuthService,
    private _oneSignalService: OneSignalService,
    private router: Router
  ) {
    this._svgRegisterService.init();
    this._dashboardService.setDashboardStatus(true);
    this.menuSubBlock = false;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
  }
  ngOnInit(){}
  ngAfterContentInit(){
    console.log("NgAfterContentInit")
    /*this._authService.subscribeIsLoggedIn()
    .subscribe(async (isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      console.log("IsLoggedIn: ", isLoggedIn)
      if(!isLoggedIn) {
        if(this._oneSignalService.wasInit()) this._oneSignalService.cleanSubscribe()
      }

      await this._oneSignalService.onInit()

      if(!isLoggedIn) this._oneSignalService.cleanSubscribe()


      if(!this.isListeningPermissionState) this.listenPermissionState()

      if(!this.isListeningSubscriptionState) this.listenSubscriptionState()

      
      if(this.isLoggedIn) {
        this._oneSignalService.showPrompt()
        this._oneSignalService.activeSubscribe()
      }

    })*/
  }

  listenPermissionState(){
    this._oneSignalService.getState(async (hasPermission) => {
      if(this.isLoggedIn && hasPermission){
        //this._oneSignalService.registerForNotifications()
        //this._oneSignalService.showPrompt()
      }
    })
  }

  listenSubscriptionState(){
    this.isListeningSubscriptionState = true
    this._oneSignalService.getSubscriptionState(async (isSubscribed) => {
      console.log("GetSubscriptionState: ", isSubscribed)
      if(this.isLoggedIn){
        if(isSubscribed ){
          const osUserId = await this._oneSignalService.getUserId()
          console.log("OneSignal userid: ", osUserId)
          const lsOneSignalID = this._authService.getOneSignalId()
          console.log("LocalStorageOneSignalId: ", lsOneSignalID)
          if(!lsOneSignalID || (lsOneSignalID != osUserId)){
            console.log("SaveOneSignalId: ", osUserId)
            this._authService.saveOneSignalId(osUserId)
            .subscribe((...data) => {
              console.log(data)
              this._authService.setOneSignalId(osUserId)
              console.log("SetOneSignalId: ", osUserId)
            })
          }
        } else {
          this._authService.saveOneSignalId('1')
            .subscribe((...data) => {
              console.log(data)
              this._authService.removeOneSignalId()
              this._oneSignalService.cleanSubscribe()
            })
        }
      }
      
    })
  }

  logout(value: boolean) {
    this.menuSubBlock = value;
    this.observable = new Observable<boolean>((observer: any) => this.observer = observer).pipe(share());
    this._authService.logout();

  }
  dropdownShow() {
    this.menuSubBlock = !this.menuSubBlock;
  }

}
