import { Injectable } from '@angular/core';
import { HttpClienteService } from '@core/services/http-cliente.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment.prod';
import { EncrDecrService } from '../encr-decr.service';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  private urlBackAuctionInfo = environment.urlBackAuctionInfo
  private urlBackAccounts = environment.urlBackAccounts

  constructor(
    private _http: HttpClienteService,
    private _encrDecrService:EncrDecrService,
  ) { }

  // Trae el litado de bancos
  getAllBank(): Observable<any> {
    const query: string = `${this.urlBackAuctionInfo}/bank/all-bank`;
    return this._http.get(query);
  }

  // Trae el listado de monedas
  getCoin(): Observable<any> {
    const query: string = `${this.urlBackAuctionInfo}/coin`;
    return this._http.get(query);
  }

  // Si todo sale ok, devolvera un listado de tipo de cuenta
  getTypeAccount(): Observable<any> {
    const query: string = `${this.urlBackAccounts}/type-account`;
    return this._http.get(query);
  }

  setBankAccount(obj: any): Observable<any> {
    const query: string = `${this.urlBackAccounts}/account-bank/create-bank-account`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.post(query, data);
  }

  getAllBankUser(){
    const query: string = `${this.urlBackAccounts}/account-bank/bank-account-list`;
    return this._http.get(query);
  }

  // trae tus cuentas de banco listadas para el select
  getBankAccountList(){
    const query: string = `${this.urlBackAuctionInfo}/account-bank/bank-account-details-list`;
    return this._http.get(query);
  }

  setUpdateBankAccount(obj: any,idBankAccount:number): Observable<any> {
    const query: string = `${this.urlBackAccounts}/account-bank/update-bank-account/${idBankAccount}`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    // console.log('data',data);
    return this._http.put(query, data);
  }

  deleteBankAccount(idBankAccount){
    const query: string = `${this.urlBackAccounts}/account-bank/delete-bank-account/${idBankAccount}`;
    return this._http.delete(query);
  }
}
