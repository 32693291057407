import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeaccountCCI'
})
export class TypeaccountCCIPipe implements PipeTransform {

  transform(value: any,arg1?:any,arg2?:any,arg3?:any): any {
    // console.log(value + ' ' + arg1 + ' ' + arg2 + ' ' ,arg3);
    //INTERBANK
    if (value.match(/^[0-9]{1,20}$/)){
      return value.substring(0,6).replace(/(\d{3})/g, '$1 ') + value.substring(6,18).replace(/(\d{12})/g, '$1 ') + value.substring(18,20).replace(/(\d{2})/g, '$1 ')
      // return value.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'');
    }
    //BANBIF
    else if (value.match(/^0[0-9]{8}(?:[0-9]{3})?$/)){
      return value
    }
    else {
      return value
    }
  }

}
