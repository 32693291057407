import { Injectable } from '@angular/core';
import { environment } from '@env/environment.prod';
import { HttpClienteService } from '@core/services/http-cliente.service';
import { EncrDecrService } from '@core/services/encr-decr.service';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@core/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterCompanyService {

  private urlBackRegister = environment.urlBackRegister;
  private urlBackInfo = environment.urlBackInfo;
  private urlBackRegCompany = environment.urlBackRegCompany;

  public fbxWidth = '';
  constructor(
    private _http: HttpClienteService,
    private _encrDecrService: EncrDecrService,
    private _localStorageService: LocalStorageService
  ) { }

  getInformationRegisterCompany(): Observable<any> {
    const query: string = `${this.urlBackRegCompany}/company/information-register-form`;
    return this._http.get(query);
  }

  getEconomicActivity(): Observable<any> {
    const query: string = `${this.urlBackRegCompany}/company/economic-activity`;
    return this._http.get(query);
  }

  setRegisterCompany(obj: any): Observable<any> {
    const query: string = `${this.urlBackRegister}/company/register-company-person-authorized`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.post(query, data);
  }

  setUpdateCompany(obj: any): Observable<any> {
    const query: string = `${this.urlBackRegCompany}/company/update-data`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    // console.log("json condificado:", data)
    return this._http.put(query, data);
  }
  // /company/upload-sheet-ruc
  // setUploadRuc(obj: FormData): Observable<any> {
  //   const query: string = `${this.url}/company/upload-sheet-ruc`;
  //   // const data = JSON.stringify({
  //   //   body: this._encrDecrService.set(obj)
  //   // });
  //   return this._http.put(query, obj);
  // }

  setSendEmailRuc(obj: any): Observable<any> {
    const query: string = `${this.urlBackRegCompany}/company/send-email-ruc`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.put(query, data);
  }

  setCloudinaryTempCompany(obj: any): Observable<any> {
    const query: string = `${this.urlBackRegCompany}/company/send-ruc-original-name-token-del`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.put(query, data);
  }

  getHistOperations(): Observable<any> {
    const query: string = `${this.urlBackInfo}/operation/get-hist-operation-list`;
    return this._http.get(query);
  }


  /* DATOS TEMPORALES DEL REGISTRO EN LOS FORMULARIOS DATA-COMPANY*/
  getTempForms() {
    const userEncr = this._localStorageService.getItem('temp-forms-company');
    return (userEncr) ? this._encrDecrService.getJson(userEncr) : null;
  }
  setTempForms(obj: any) {// el dato ya llega encriptado
    // const userEncr = this._encrDecrService.set(obj);
    this._localStorageService.setItem('temp-forms-company', obj);
  }
  removeTempForms() {
    this._localStorageService.removeItem('temp-forms-company');
  }




}
