import { Injectable } from '@angular/core';
import { environment } from '@env/environment.prod';
import { HttpClienteService } from '@core/services/http-cliente.service';
import { EncrDecrService } from '@core/services/encr-decr.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private urlBackReset = environment.urlBackReset;

  constructor(
    private _http: HttpClienteService,
    private _encrDecrService:EncrDecrService
  ) { }

  getValidateEmail(codeConfirm:any): Observable<any> {
    const query: string = `${this.urlBackReset}/confirmation-email/${codeConfirm}`;
    return this._http.get(query);
  }
  getValidatePasswordRecoveryCode(codeConfirm:any): Observable<any> {
    const query: string = `${this.urlBackReset}/password-recovery/validate/${codeConfirm}`;
    return this._http.get(query);
  }


  getPasswordRecovery(obj: any,recoveryCode:any): Observable<any> {
    const query: string = `${this.urlBackReset}/password-recovery/${recoveryCode}`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.put(query, data);
  }
}
