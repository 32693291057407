import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeaccount'
})
export class TypeaccountPipe implements PipeTransform {

  transform(value: any,arg1?:any,arg2?:any,arg3?:any): any {
    //console.log(value + ' ' + arg1 + ' ' + arg2 + ' ' ,arg3);
    // MASTER CARD
    if (value.match(/^4[0-9]{12}(?:[0-9]{3})?$/)) {
      return value.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'');
    }
    //VISA
    else if (value.match(/^4[0-9]{12}(?:[0-9]{3})?$/)){
      return value.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'');
    }
    //BANBIF
    else if (value.match(/^0[0-9]{8}(?:[0-9]{3})?$/)){
      return value
      // return value.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'');
    }
    //BBVA
    else if (value.match(/^0[0-9]{7}(?:[0-9]{10})?$/)){
      return value.substring(0,8).replace(/(\d{4})/g, '$1 ') + value.substring(8,18)
      // return value.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'');
    }
    //BCP
    else if (value.match(/^1[0-9]{10}(?:[0-9]{2})?$/)){
      return value.substring(0,3).replace(/(\d{3})/g, '$1 ')  + value.substring(3,10).replace(/(\d{7})/g, '$1 ') + value.substring(10,11).replace(/(\d{1})/g, '$1 ') + value.substring(11,13).replace(/(\d{2})/g, '$1 ')
      // return value.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'');
    }
    //INTERBANK
    else if (value.match(/^2[0-9]{10}(?:[0-9]{2})?$/)){
      return value.substring(0,3).replace(/(\d{3})/g, '$1 ') + value.substring(3,13).replace(/(\d{10})/g, '$1 ')
      // return value.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'');
    }
    //PICHINCHA
    else if (value.match(/^0[0-9]{2}(?:[0-9]{12})?$/)){
      return value.substring(0,3).replace(/(\d{3})/g, '$1 ') + value.substring(3,15).replace(/(\d{12})/g, '$1 ')
      // return value.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'');
    }
    //SCOTIABANK
    else if (value.match(/^0[0-9]{2}(?:[0-9]{7})?$/)){
      return value.substring(0,3).replace(/(\d{3})/g, '$1 ') + value.substring(3,10).replace(/(\d{7})/g, '$1 ')
      // return value.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'');
    }
    else {
      return value
    }
  }

}
