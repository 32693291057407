import { Injectable } from '@angular/core';
import { environment } from '@env/environment.prod';
import { Router } from '@angular/router';
import { HttpClienteService } from '@core/services/http-cliente.service';
import { Observable } from 'rxjs';
import { Login } from '@core/interfaces/login';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { EncrDecrService } from '@core/services/encr-decr.service';
import { User } from '@core/interfaces/user';
import { Company } from '@core/interfaces/company';

// otros servicios del negocio
import { RegisterCompanyService } from '@core/services/company/register-company.service';
import { RegisterPersonService } from '@core/services/person/register-person.service';
import { Subscriber } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  getFondOrigin() {
    throw new Error("Method not implemented.");
  }

  private token: string;
  private urlAuth = environment.urlBackAuth;
  private urlBackReset = environment.urlBackReset;
  private urlBackProfile = environment.urlBackProfile;

  private obsIsLoggedIn: Observable<boolean> = null;
  private subscriberIsLoggedIn: Subscriber<boolean> = null;

  user: User;
  company: Company;

  constructor(
    public router: Router,
    private _http: HttpClienteService,
    private _localStorageService: LocalStorageService,
    private _encrDecrService: EncrDecrService,
    private _registerPersonService: RegisterPersonService,
    private _registerCompanyService: RegisterCompanyService
  ) {
    this.token = this.getToken();
    this.obsIsLoggedIn = new Observable((subscriber) => {
      console.log(subscriber)
      this.subscriberIsLoggedIn = subscriber;
      this.subscriberIsLoggedIn && this.subscriberIsLoggedIn.next(this.isLoggedIn())
    })
  }

  subscribeIsLoggedIn(): Observable<boolean> {
    return this.obsIsLoggedIn
  }

  pushIsLoggedIn(): void {
    console.log("Push is loggedin")
    this.subscriberIsLoggedIn && this.subscriberIsLoggedIn.next(this.isLoggedIn());
  }

  getToken(): string {
    const tokenEncr = this._localStorageService.getItem('token');
    return (tokenEncr) ? this._encrDecrService.get(tokenEncr) : null;
  }
  setToken(token: string) {
    // console.log('token recibido', token)
    const tokenEncr = this._encrDecrService.set(token);
    this._localStorageService.setItem('token', tokenEncr);
    this.token = this.getToken();
    if (token) this.pushIsLoggedIn();
  }
  removeToken() {
    this._localStorageService.removeItem('token');
    this.token = this.getToken();
  }

  getUser() {
    const userEncr = this._localStorageService.getItem('user');
    return (userEncr) ? this._encrDecrService.getJson(userEncr) : null;
  }
  setUser(obj: any) {
    const userEncr = this._encrDecrService.set(obj);
    this._localStorageService.setItem('user', userEncr);
    this.user = this.getUser();
  }
  removeUser() {
    this._localStorageService.removeItem('user');
    this.user = this.getUser();
  }

  getOneSignalId() {
    const osEncr = this._localStorageService.getItem('oneSignalId');
    return (osEncr) ? this._encrDecrService.getJson(osEncr).oneSignalId : null;
  }

  setOneSignalId(oneSignalId: string): void {
    const osEncr = this._encrDecrService.set({ oneSignalId });
    this._localStorageService.setItem('oneSignalId', osEncr)
  }

  removeOneSignalId() {
    this._localStorageService.removeItem('oneSignalId');
  }

  getCompany() {
    const userEncr = this._localStorageService.getItem('company');
    return (userEncr) ? this._encrDecrService.getJson(userEncr) : null;
  }
  setCompany(obj: any) {
    const userEncr = this._encrDecrService.set(obj);
    this._localStorageService.setItem('company', userEncr);
    this.company = this.getCompany();
  }
  removeCompany() {
    this._localStorageService.removeItem('company');
    this.company = this.getCompany();
  }

  login(obj: Login): Observable<any> {
    const query = `${this.urlAuth}/authentication/login`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    // console.log('body',data);
    return this._http.post(query, data);
  }

  logout() {
    this.saveOneSignalId('1')
      .subscribe((obs) => console.log(obs))
    this.removeToken();
    this.removeUser();
    this.removeCompany();
    this.removeOneSignalId()
    this.pushIsLoggedIn();
    localStorage.removeItem("finantialListFiltered");
    localStorage.removeItem("tempData");

    this._registerPersonService.removeTempForms();
    this._registerCompanyService.removeTempForms();

    window.location.href = "https://firbid.com/";
    /*     this.router.navigate(['/login']); */
  }

  isLoggedIn(): boolean {
    const token = this.getToken();
    return token !== null
  }

  getDataUser(): Observable<any> {
    const query: string = `${this.urlAuth}/authentication/login-data`;
    return this._http.get(query);
  }

  getClientProfile(): Observable<any> {
    const query: string = `${this.urlBackProfile}/client/profile`;
    return this._http.get(query);
  }

  getCompanyProfile(): Observable<any> {
    const query: string = `${this.urlBackProfile}/company/profile`;
    return this._http.get(query);
  }

  setChangePassword(obj: any): Observable<any> {
    const query: string = `${this.urlBackProfile}/password-recovery/change`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.post(query, data);
  }

  setEmailPasswordRecovery(obj: any): Observable<any> {
    const query: string = `${this.urlBackReset}/password-recovery/send-email`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.post(query, data);
  }
  setChangeCellphone(obj: any): Observable<any> {
    const query: string = `${this.urlBackProfile}/user/upd-phone`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.put(query, data);
  }
  setChangeEmail(obj: any): Observable<any> {
    const query: string = `${this.urlBackProfile}/user/email`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.put(query, data);
  }

  saveOneSignalId(oneSignalId: string) {
    const query: string = `${this.urlBackProfile}/one-signal`;
    const data = JSON.stringify({
      body: this._encrDecrService.set({ oneSignalId })
    });
    return this._http.post(query, data)
      .pipe(take(1));
  }

}
