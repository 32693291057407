import { Injectable } from '@angular/core';
import { environment } from '@env/environment.prod';
import { HttpClienteService } from '@core/services/http-cliente.service';
import { EncrDecrService } from '@core/services/encr-decr.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  private urlBackProfile = environment.urlBackProfile;
  private urlBackAuctionInfo = environment.urlBackAuctionInfo;
  private urlBackRegNatural = environment.urlBackRegNatural;

  constructor(
    private _http: HttpClienteService,
    private _encrDecrService:EncrDecrService
  ) { }

  getListPais(): Observable<any> {
    const query: string = `${this.urlBackProfile}/country/data`;
    return this._http.get(query);
  }

  getDepartments(): Observable<any> {
    const query: string = `${this.urlBackRegNatural}/place/departments`;
    return this._http.get(query);
  }
  getProvinces(idDepartment: number): Observable<any> {
    const query: string = `${this.urlBackRegNatural}/place/provinces/${idDepartment}`;
    return this._http.get(query);
  }
  getDistricts(idProvince: number): Observable<any> {
    const query: string = `${this.urlBackRegNatural}/place/districts/${idProvince}`;
    return this._http.get(query);
  }
  
  getFondOrigin(): Observable<any> {
    const query: string = `${this.urlBackAuctionInfo}/origin-fonds`;
    return this._http.get(query);
  }
}
