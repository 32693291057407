import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment.prod';
import { HttpClienteService } from '../http-cliente.service';
import { EncrDecrService } from '../encr-decr.service';

@Injectable({
  providedIn: 'root'
})
export class AuctionService {

  private urlBackAuctionInfo = environment.urlBackAuctionInfo;
  private urlBackAuctionProcess = environment.urlBackAuctionProcess;
  private urlBackAuctionPay = environment.urlBackAuctionPay;
  
  //DATASERVICE
  private messageSource = new BehaviorSubject([]);
  currentMessage = this.messageSource.asObservable();
  //END DATASERVICE
  constructor(
    private _http: HttpClienteService,
    private _encrDecrService:EncrDecrService,
  ) { }

  setAuction(obj: any): Observable<any> {
    const query: string = `${this.urlBackAuctionProcess}/auction/create`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    // console.log('data encriptada',data);
    return this._http.post(query, data);
  }

  getAuctionById(id:any): Observable<any> {
    const query: string = `${this.urlBackAuctionProcess}/auction/initiated/${id}`;
    return this._http.get(query);
  }

  confirmPassword(obj: any,id:any): Observable<any> {
    const query: string = `${this.urlBackAuctionProcess}/auction/confirmation/${id}`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.put(query, data);
  }

  getAuctionDetailById(id:any): Observable<any> {
    const query: string = `${this.urlBackAuctionPay}/auction/payment-confirmation/${id}`;
    return this._http.get(query);
  }

  confirmPayment(obj: any,id:any): Observable<any> {
    const query: string = `${this.urlBackAuctionPay}/auction/payment/${id}`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.put(query, data);
  }

  tracking(idOperation :any) : Observable<any> {
    const query: string = `${this.urlBackAuctionPay}/auction/tracking/${idOperation}`;
    return this._http.get(query);
  }

  setAuctionLeave(obj: any,id:any): Observable<any> {
    const query: string = `${this.urlBackAuctionProcess}/auction/abandoned/${id}`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.put(query, data);
  }

  setViewOperationComplete(obj: any,id:any): Observable<any> {
    const query: string = `${this.urlBackAuctionPay}/auction/view-operation-financed/${id}`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.put(query, data);
  }

  getAuctionList(): Observable<any> {
    const query: string = `${this.urlBackAuctionInfo}/auction/list-active`;
    return this._http.get(query);
  }

  getUnfulfilled():Observable<any>{
    const query: string = `${this.urlBackAuctionInfo}​/auction/unfulfilled-detail`
    return this._http.get(query);
  }

  setUnfulfilled(obj:any,idOperation:number ):Observable<any>{
    const query: string = `${this.urlBackAuctionInfo}​/auction/upload-unfulfilled-voucher/${idOperation}`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    // console.log('data encriptada',data);
    return this._http.put(query,data);
  }

  changeMessage(message: any) {
    this.messageSource.next(message)
  }

  getCanStart(): Observable<any> {
    const query: string = `${this.urlBackAuctionInfo}/auction/confirm-customer-payments-to-us`;
    return this._http.get(query);
  }

  isAllowed(): Observable<any> {
    const query: string = `${this.urlBackAuctionInfo}/auction/start-rango-end`;
    return this._http.get(query);
  }
  getAttentionHours():Observable<any>{
    const query: string = `${this.urlBackAuctionInfo}​/auction/start-rango-end`;
    // console.log("RUTA GET:", query);
    return this._http.get(query);
  }
}
