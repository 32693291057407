import { Injectable } from '@angular/core';
import { environment } from '@env/environment.prod';
import { HttpClienteService } from '@core/services/http-cliente.service';
import { EncrDecrService } from '@core/services/encr-decr.service';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@core/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterPersonService {

  private urlBackRegister = environment.urlBackRegister;
  private urlBackRegNatural = environment.urlBackRegNatural;
  
  public fbxWidth = '';
  public fbxTitleBar = '';
  constructor(
    private _http: HttpClienteService,
    private _encrDecrService: EncrDecrService,
    private _localStorageService: LocalStorageService
  ) { }


  getInformationRegisterPerson(): Observable<any> {
    const query: string = `${this.urlBackRegNatural}/client/information-register-form`;
    return this._http.get(query);
  }
  setRegisterPerson(obj: any): Observable<any> {
    const query: string = `${this.urlBackRegister}/client/register-personal-information`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    // console.log("query: ", query);
    // console.log("data: ", data);
    return this._http.post(query, data);
  }

  // getInitialPerson(): Observable<any> {
  //   const query: string = `${this.url}/client/initial-data`;
  //   return this._http.get(query);
  // }

  setSendDocument(obj: any): Observable<any> {
    const query: string = `${this.urlBackRegNatural}/client/send-dni-with-verified-email`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.post(query, data);
  }

  setUpdatePerson(obj: any): Observable<any> {
    const query: string = `${this.urlBackRegNatural}/client/update`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    // console.log("data:",data)
    return this._http.put(query, data);
  }

  setCloudinaryTempPerson(obj: any): Observable<any> {
    const query: string = `${this.urlBackRegNatural}/client/send-dni-original-name-token-del`;
    const data = JSON.stringify({
      body: this._encrDecrService.set(obj)
    });
    return this._http.put(query, data);
  }



  /* DATOS TEMPORALES DEL REGISTRO EN LOS FORMULARIOS DATA-PERSON*/
  getTempForms() {
    const userEncr = this._localStorageService.getItem('temp-forms-person');
    return (userEncr) ? this._encrDecrService.getJson(userEncr) : null;
  }
  setTempForms(obj: any) {// el dato ya llega encriptado
    // const userEncr = this._encrDecrService.set(obj);
    this._localStorageService.setItem('temp-forms-person', obj);
  }
  removeTempForms() {
    this._localStorageService.removeItem('temp-forms-person');
  }

}
