import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SvgRegisterService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}
  init() {
    this.matIconRegistry.
      addSvgIcon(
        'icon-fbx-person',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/persona.svg')
      )
      .addSvgIcon(
        'icon-fbx-bag',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/maleta-curva.svg')
      )
      
      .addSvgIcon(
        'icon-fbx-arrow',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/arrow.svg')
      )
      //Listado de íconos header
      .addSvgIcon(
        'icon-fbx-ajustes',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/ajustes.svg')
      )
      .addSvgIcon(
        'icon-fbx-soporte',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/wsp.svg')
      )
      .addSvgIcon(
        'icon-fbx-arrow-p',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/arrow_p.svg')
      )
      .addSvgIcon(
        'icon-fbx-arrow-r',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/arrow_r.svg')
      )
      .addSvgIcon(
        'icon-fbx-perfil',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/perfil.svg')
      )
      .addSvgIcon(
        'icon-fbx-cerrarsesion',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/cerrarsesion.svg')
      )
      //Listado de íconos menú lateral
      .addSvgIcon(
        'icon-fbx-subastas',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/m1.svg')
      )
      .addSvgIcon(
        'icon-fbx-close',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/close.svg')
      )
      .addSvgIcon(
        'icon-fbx-operaciones',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/m2.svg')
      )
      .addSvgIcon(
        'icon-fbx-mailinput',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/correoregistro.svg')
      )
      .addSvgIcon(
        'icon-fbx-ruc',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/maletaruc.svg')
      )
      .addSvgIcon(
        'icon-fbx-personaregistro',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/personaregistro.svg')
      )
      .addSvgIcon(
        'icon-fbx-cuentas-de-banco',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/m3.svg')
      )
      .addSvgIcon(
        'icon-fbx-fideicomiso',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/m4.svg')
      ) 
      //Listado de íconos  menú responsive
      .addSvgIcon(
        'icon-fbx-res-operacion',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/responsive/operaciones.svg')
      )
      .addSvgIcon(
        'icon-fbx-res-mis-cuentas',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/responsive/mis-cuentas.svg')
      )
      .addSvgIcon(
        'icon-fbx-res-favicon',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/favicon.svg')
      )
      .addSvgIcon(
        'icon-fbx-res-fideicomiso',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/responsive/fideicomiso.svg')
      )
      .addSvgIcon(
        'icon-fbx-res-perfil',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/responsive/perfil.svg')
      )
      .addSvgIcon(
        'icon-fbx-res-trash',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/trash.svg')
      )
      .addSvgIcon(
        'icon-add-actionist',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icon-add-actionist.svg')
      )
      .addSvgIcon(
        'icon-question',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icon-question.svg')
      )
      .addSvgIcon(
        'icon-building',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icon_building.svg')
      )
      .addSvgIcon(
        'icon-clip',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/clip.svg')
      );
      
  }

  initSubasta(){
    this.matIconRegistry
      .addSvgIcon(
        'icon-check-auction',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/subasta/check-seguimiento.svg')
      )
      .addSvgIcon(
        'icon-clock-auction',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/subasta/reloj-subasta.svg')
      );
  }
}
