import {
    ComponentRef,
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit
} from "@angular/core";
import {
    Overlay,
    OverlayPositionBuilder,
    OverlayRef
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { FirbidTooltipComponent } from "./firbid-tooltip.component";

interface IOffset {
    X : number,
    Y : number
};

@Directive({ selector: "[firbidTooltip]" })
export class FirbidTooltipDirective implements OnInit {
    @Input("firbidTooltip") text = "";
    @Input("firbidImg") firbidImg = "";
    @Input("offset") offset: IOffset = { X: null , Y: null };
    private overlayRef: OverlayRef;

    constructor(
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private elementRef: ElementRef
    ) { }

    ngOnInit(): void {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([
                {
                    originX: "center",
                    originY: "top",
                    overlayX: "center",
                    overlayY: "bottom",
                    offsetY: (this.offset.Y)? this.offset.Y: -8,
                    offsetX: (this.offset.X)? this.offset.X: 0,
                }
            ]);

        this.overlayRef = this.overlay.create({ positionStrategy });
    }

    @HostListener("mouseenter")
    show() {
        const tooltipRef: ComponentRef<
            FirbidTooltipComponent
        > = this.overlayRef.attach(new ComponentPortal(FirbidTooltipComponent));
        tooltipRef.instance.text = this.text;
        tooltipRef.instance.img = this.firbidImg;
    }

    @HostListener("mouseout")
    hide() {
        this.overlayRef.detach();
    }
}
