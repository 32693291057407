import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loadingbtn',
  templateUrl: './loadingbtn.component.html',
  styleUrls: ['./loadingbtn.component.scss']
})
export class LoadingbtnComponent implements OnInit {
  @Input() txtButton: String;
  @Input() btnSubject:Subject<boolean>;
  loading = false;
  constructor() { }

  ngOnInit() {
    this.btnSubject.subscribe(event => {
      // called when the notifyChildren method is
      // called in the parent component
      // console.log('event -----', event)
      this.loading = event;
    });
  }

  save(): void {
    // console.log('save')
    this.loading = true;

  }

  ngOnDestroy() {
    // needed if child gets re-created (eg on some model changes)
    // note that subsequent subscriptions on the same subject will fail
    // so the parent has to re-create btnSubject on changes
    this.btnSubject.unsubscribe();
  }

}
