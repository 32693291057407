// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  cloudinarySettings: {
    cloud_name: 'firbix',
    upload_preset: 'ml_default'
  },
  aws: {
    accessKeyId: 'AKIAI5SDYNKM2VJNGPUQ',
    secretAccessKey: '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',
    bucket: 'file-firbix'
  },
  //DEVELOP-------------
  // urlFirbix: 'http://169.62.189.115:3000',
  // urlFirbixEf: 'http://169.62.189.115:3002',
  urlBackAuth: 'https://prod-back-auth.firbid.com',
  urlBackReset: 'https://prod-back-reset.firbid.com',
  urlBackRegister: 'https://prod-back-register.firbid.com',
  urlBackSunat: 'https://prod-back-security.firbid.com',
  urlBackProfile: 'https://prod-back-profile.firbid.com',
  urlBackAuctionInfo: 'https://prod-back-auction-info.firbid.com',
  urlBackAuctionProcess: 'https://prod-back-auction-process.firbid.com',
  urlBackAuctionPay: 'https://prod-back-auction-pay.firbid.com',
  urlBackAccounts: 'https://prod-back-accounts.firbid.com',
  urlBackInfo: 'https://prod-back-info.firbid.com',
  urlBackRegNatural: 'https://prod-back-reg-natural.firbid.com',
  urlBackRegCompany: 'https://prod-back-reg-company.firbid.com',

  urlSocket: 'https://prod-back-cliente-socket.firbid.com',

  keyCrypto: 'bQeThWmYq3t6w9z$C&F)J@NcRfUjXn2r'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */