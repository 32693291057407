import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { PersonDataComponent } from '../../data-person/person-data/person-data.component';

@Component({
  // providers:[ PersonDataComponent ],
  selector: 'app-modal-register-leave',
  templateUrl: './modal-register-leave.component.html',
  styleUrls: ['./modal-register-leave.component.scss']
})
export class ModalRegisterLeaveComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ModalRegisterLeaveComponent>,
    private router: Router
    // public register: PersonDataComponent
  ) { }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

  save(){
    this.router.navigate(['main/subasta']);
    this.dialogRef.close();
  }

}
