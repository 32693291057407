import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomPreloadStrategyService } from '../app/core/services/selective-preload-strategy/custom-preload-strategy.service'

// Guards
import { LoginGuard } from '@core/guards/login.guard.service';

// Components
import { LoginComponent } from './features/login/login.component';
import { LoginFromTokenComponent } from './features/login-from-token/login-from-token.component';
const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { preload: true } },
  {
    path: 'auth', component: LoginFromTokenComponent, 
    // ,canActivate: [LoginGuard]
  },
  {
    path: 'recuperar-contraseña',
    loadChildren: () => import('./features/resetpass/resetpass.module').then(m => m.ResetpassModule), data: { preload: true }
  },
  {
    path: 'registro',
    loadChildren: () => import('./features/register/register.module').then(m => m.RegisterModule), data: { preload: true }
  },
  {
    canActivate: [LoginGuard],
    // canLoad: [LoginGuard],
    path: 'main', loadChildren: () => import('./shared/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  { path: 'reinicio/:token', loadChildren: () => import('./features/reboot/reboot.module').then(m => m.RebootModule) },
  { path: 'notificaciones', loadChildren: () => import('./features/notifications/notifications.module').then(m => m.NotificationsModule) },
  { path: '**', pathMatch: 'full', redirectTo: 'login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Pre cargar SOLO los módulso que yo quiero con , data: {preload:true}
      preloadingStrategy: CustomPreloadStrategyService,
      // Restaurar la posición base (scrollTop = 0), en cada ruta
      'scrollPositionRestoration': 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
