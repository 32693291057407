import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  dashboardStatus: boolean;
  dashboardTitle: string;
  dashboardSidenavStatus: boolean;
  
  constructor() {
    this.dashboardStatus = (this.dashboardStatus) ? this.dashboardStatus : false;
    this.dashboardSidenavStatus  = (this.dashboardSidenavStatus) ? this.dashboardSidenavStatus : true;
  }

  setDashboardStatus(status: boolean, title?:string) {
    this.dashboardStatus = status;
    this.dashboardTitle = (title) ? title : '';
  }

  setDashboardSidenavStatus(status: boolean, title?:string) {
    this.dashboardSidenavStatus = status;
  }
}
