import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from '../../core/services/users/auth.service';
import { EncrDecrService } from '../../core/services/encr-decr.service';
import { MatSnackBar } from '@angular/material';
import { timer, of } from 'rxjs';


@Component({
  selector: 'app-login-from-token',
  templateUrl: './login-from-token.component.html',
  styleUrls: ['./login-from-token.component.scss']
})
export class LoginFromTokenComponent implements OnInit {

  flag = false;

  constructor(
    private route: ActivatedRoute,
    private _authService: AuthService,
    private _encrDecrService: EncrDecrService,
    private router: Router,
    public snackBar: MatSnackBar,
  ) {

    timer(1800).subscribe(() => {
      this.flag = true;
    });


    this.route.queryParams
      .pipe(take(1))
      .subscribe(params => {
        const token = params.token;
        this._authService.setToken(token);
        this.autenticar();
      })
  }

  ngOnInit() {

  }

  autenticar() {


    this._authService.getDataUser().subscribe((res: any) => {

      if (res) {
        //  const result = this._encrDecrService.getJson(res);
        const result = this._encrDecrService.getJson(res.value);




        

        if (result.typeUser) {
          let resCompany = {};
          switch ((result.typeUser).toLowerCase()) {
            case 'natural':
              this._authService.setUser(result);
              break;
            case 'company': resCompany = {
              numberDocument: result.company.numberDocument,
              //  createAt: result.company.createAt,
              name: result.company.name
            };
              this._authService.setCompany(resCompany);
              this._authService.setUser(result);

              break;
            case 'juridico': resCompany = {
              numberDocument: result.company.numberDocument,
              // createAt: result.company.createAt,
              name: result.company.name
            };
              this._authService.setCompany(resCompany);
              this._authService.setUser(result);

              break;
            default:
              break;
          }
          this.router.navigate(['/main/subasta'], { replaceUrl: true });
        } else {
          this.router.navigate(['/login']).then(() => {
            this.snackBar.open('Ocurrio un error durante el Login', 'error', {
              duration: 5000,
            });
          });
        }
      } else {

        this.router.navigate(['/login'], { replaceUrl: true }).then(() => {
          this.snackBar.open('Ocurrio un error durante el Login', 'error', {
            duration: 5000,
          });
        });

      }
    });
  }


  /*   auth() {
      const json = { "natural": { "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlLZXlTZWNyZXQiOiI3ZjBmZDEyMy0yYzUxLTQ4YzYtOTY0Ny0zZjljMDQ1NDkyYmYiLCJudW1iZXJEb2N1bWVudCI6IjcxMjU2NjQ4IiwiZW1haWwiOiJ1bm1pa2VzdGFibGVAZ21haWwuY29tIiwicGhvbmUiOiI5OTQ5NjI0MTYiLCJpYXQiOjE2NDMzMTIxMDR9.0iBu9hPAIDc48qC_VPrY3FImcxM4Zpb9_kFUbI-aoV8", "name": "MIGUEL ANGEL", "phone": "994962416", "createAt": "2022-01-14T17:21:27.000Z", "typeDocument": 1, "numberDocument": "71256648", "email": "unmikestable@gmail.com", "lastNameMaternal": "LUDEÑA", "lastNamePaternal": "RAMIREZ", "typeUser": "natural", "statusCompletefirstForm": true, "statusCompleteSecondForm": false, "statusCompleteThreeForm": true, "userCode": "561cee47-d85d-410b-b345-d33ad17baa5c", "status": 3 }, "typeUser": "natural" }
      const encry = this._encrDecrService.set(json);
      return of(encry)
  
  
  
    }
   */



}
