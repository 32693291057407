import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppErrorHandler } from './error-handler/app-error-handler.service';

/****** Interceptors ******/
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HeaderInterceptor } from './interceptors/header.interceptor';

/****** Servicios ******/
// Config
import { LocalStorageService } from './local-storage/local-storage.service';
import { EncrDecrService } from './services/encr-decr.service';
import { HttpClienteService } from './services/http-cliente.service';
import { AuthService } from './services/users/auth.service';
// Guard
import { LoginGuard } from './guards/login.guard.service';
import { ComponentGuard } from './guards/component.guard.service';
// Normales
import { RegisterService } from './services/users/register.service';
import { SvgRegisterService } from './material/svg-register.service';
import { DashboardService } from './services/resources/dashboard.service';
import { ResourcesService } from './services/resources/resources.service';
import { NotificationsService } from './services/resources/notifications.service';
import { AwsUploadService } from './services/aws-upload.service';
import { RegisterPersonService } from './services/person/register-person.service';
import { RegisterCompanyService } from './services/company/register-company.service';
import { BankService } from './services/banks/bank.service';
import { AccountFormatDirective } from './directive/account-format.directive';
import { TypeaccountPipe } from './pipe/typeaccount.pipe';
import { AuctionService } from './services/auction/auction.service';
import { CustomDatePipe } from './pipe/custom-date.pipe';
import { TypeaccountCCIPipe } from './pipe/typeaccount-cci.pipe';
import { TimerService } from './services/timer/timer.service';

const SERVICES = [
  LocalStorageService,
  EncrDecrService,
  HttpClienteService,
  AuthService,
  LoginGuard,
  ComponentGuard,
  RegisterService,
  SvgRegisterService,
  DashboardService,
  ResourcesService,
  NotificationsService,
  AwsUploadService,
  RegisterPersonService,
  RegisterCompanyService,
  BankService,
  AuctionService,
  TimerService
];

const COMPONENTS = [];

const INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }
  // { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: ConvertInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: FakeInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: HttpsInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: ProfilerInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: NotifyInterceptor, multi: true }
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    // angular
    CommonModule,
    HttpClientModule,

    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
    ...SERVICES,
    ...INTERCEPTORS,
    { provide: ErrorHandler, useClass: AppErrorHandler },
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
