import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '@core/services/users/auth.service';

@Injectable()
export class ComponentGuard implements CanActivate {
  constructor(
    private router: Router,
    private service: AuthService
  ) {
  }

  canActivate(): boolean {

    if (this.service.isLoggedIn()) {
      // this.router.navigate(['/main/']); 
       return true;
    } else {
       this.router.navigate(['/login']);
      return false;
    }
  }
}
