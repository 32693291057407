import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Cloudinary } from '@cloudinary/angular-5.x';
import { Image } from '@core/interfaces/image';
import { FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';

@Component({
  selector: 'app-cloudinary',
  templateUrl: './cloudinary.component.html',
  styleUrls: ['./cloudinary.component.scss']
})
export class CloudinaryComponent implements OnInit, OnChanges {

  @Input() title;
  @Input() previousImage: any;
  @Output() cloudinaryResponse = new EventEmitter();
  URL_UPLOAD_CLOUDINARY = `https://api.cloudinary.com/v1_1/${this.cloudinary.config().cloud_name}/upload`;
  URL_DELETE_CLOUDINARY = `https://api.cloudinary.com/v1_1/${this.cloudinary.config().cloud_name}/delete_by_token`;
  @ViewChild('fbxFileInput', { static: false }) fileInputElement: ElementRef;
  existImage: boolean = false;
  delete_token: string
  imageDelete: any;
  imagen: Image = {
    nombre: '',
    imagen: '',
    token: ''
  };
  public hasBaseDropZoneOver: boolean = false;
  public uploader: FileUploader;

  constructor(
    private cloudinary: Cloudinary,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.cloudinaryInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    let previousImage = changes['previousImage'];
    if(previousImage && previousImage.currentValue && previousImage.currentValue.nombre !== null) {
      this.existImage = true;
      this.imagen = previousImage.currentValue;
    }
  }

  cloudinaryInit() {

    const uploaderOptions: FileUploaderOptions = {
      url: this.URL_UPLOAD_CLOUDINARY,
      autoUpload: true,
      isHTML5: true,
      allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'],
      maxFileSize: 5 * 1024 * 1024,
      removeAfterUpload: true,
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    };

    const upsertResponse = (fileItem) => {
      // Check if HTTP request was successful
      if (fileItem.status !== 200) {
        //console.log('Upload to cloudinary Failed', fileItem);
        return false;
      }

      this.imagen.nombre = fileItem.file.name;
      this.imagen.imagen = fileItem.data.secure_url;
      this.imagen.token = fileItem.data.delete_token

      if (this.existImage) {
        this.deleteImage(this.imageDelete.data);
      }
      this.imageDelete = fileItem;
      this.existImage = true;
      this.cloudinaryResponse.emit(this.imagen);
    }

    this.uploader = new FileUploader(uploaderOptions);
    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      form.append('upload_preset', this.cloudinary.config().upload_preset);
      form.append('folder', 'vouchers');
      form.append('file', fileItem);
      fileItem.withCredentials = false;
      return { fileItem, form };
    }

    // Actualizar el modelo al finalizar la carga de un archivo
    this.uploader.onCompleteItem = (item: any, response: string, status: number, headers: ParsedResponseHeaders) =>
      upsertResponse(
        {
          file: item.file,
          status,
          data: JSON.parse(response),
        }
      );

    // Update model on upload progress event
    this.uploader.onProgressItem = (fileItem: any, progress: any) =>
      upsertResponse(
        {
          file: fileItem.file,
          progress,
          data: {}
        }
      );

    this.uploader.onWhenAddingFileFailed = (fileItem, filter) => {
      this.snackBar.open('Solo se acepta documentos PDF de máximo 5MB', 'error', {
        duration: 2000,
      });
    }
    this.uploader.onAfterAddingFile = f => {
      if (this.uploader.queue.length > 1) {
        this.uploader.removeFromQueue(this.uploader.queue[0]);
      }
    };
  }

  onImageClicked() {
    this.fileInputElement.nativeElement.click();
  }

  deleteImage = function (data: any) {
    const url = this.URL_DELETE_CLOUDINARY;
    const headers = new Headers({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' });
    const options = { headers: headers };
    const body = {
      token: data.delete_token
    };

    this._http.post(url, body, options).subscribe(response => {
    });
  };

  public fileOverBase(e: boolean): void {
    this.hasBaseDropZoneOver = e;
  }

  seeRuc() {
    if (this.imagen && this.imagen.imagen) {
      window.open(this.imagen.imagen);
    } else {
      this.snackBar.open('No tiene ficha ruc cargado', 'error', {
        duration: 2000,
      });
    }
  }

}
