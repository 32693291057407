import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/****** Shared and Core Module ******/
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from './core/core.module';

/****** Routes ******/
import { AppRoutingModule } from './app-routing.module';

/****** Componentes ******/
import { AppComponent } from './app.component';
import { LoginComponent } from './features/login/login.component';
import { LoginFromTokenComponent } from './features/login-from-token/login-from-token.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginFromTokenComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    // core & shared
    CoreModule,
    SharedModule,
    AppRoutingModule
   ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
 }