import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
// import { DragDropModule } from '@angular/cdk/drag-drop';
// import { ScrollingModule } from '@angular/cdk/scrolling';
//import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

/****** Banderas Css ngx-flag-icon-css ******/
// Codigo ISO 2 (ISO 3166-1-alpha-2 code)
// https://www.npmjs.com/package/ngx-flag-icon-css
// https://github.com/lipis/flag-icon-css
// -> npm install ngx-flag-icon-css flag-icon-css
// <flag-icon country="pe" squared></flag-icon>
import { NgxFlagIconCssModule } from 'ngx-flag-icon-css';

/****** Material Angular ******/
import { MyMaterialModule } from '@shared/material/material.module';

import { LoadingfullComponent } from './loadingfull/loadingfull.component';
 
import { FileUploadModule } from 'ng2-file-upload';
import { TypeaccountPipe } from '@core/pipe/typeaccount.pipe';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CustomDatePipe } from '@core/pipe/custom-date.pipe';

import localeEspE from '@angular/common/locales/es-PE';
import { TypeaccountCCIPipe } from '@core/pipe/typeaccount-cci.pipe';
import { LoadingbtnComponent } from './loadingbtn/loadingbtn.component';
import { PasswordStrengthBarComponent } from './password-strength-bar/password-strength-bar.component';
import { FirbidHeaderComponent } from './firbid-header/firbid-header.component';
import { CloudinaryComponent } from './cloudinary/cloudinary.component';
import { FirbidTooltipComponent } from './firbid-tooltip/firbid-tooltip.component';
import { FirbidTooltipDirective } from './firbid-tooltip/firbid-tooltip.directive';


registerLocaleData(localeEspE, 'es-PE');

const MODULES = [
  CommonModule,
  RouterModule,
  //FormsModule,
  //ReactiveFormsModule,
  // ScrollingModule,
  // DragDropModule,
  MyMaterialModule,
  NgxFlagIconCssModule, 
  FileUploadModule,
  // NgbModule
];

const COMPONENTS = [
  LoadingfullComponent,
  LoadingbtnComponent,
  CustomDatePipe,
  TypeaccountPipe,
  TypeaccountCCIPipe,
  PasswordStrengthBarComponent,
  FirbidHeaderComponent,
  CloudinaryComponent,
  FirbidTooltipDirective,
  FirbidTooltipComponent
];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES, ...COMPONENTS],
  providers: [
     { provide: LOCALE_ID, useValue: 'es-PE' }
  ],
  declarations: [...COMPONENTS,],
  entryComponents: [FirbidTooltipComponent]
})
export class SharedModule {}
