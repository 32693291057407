import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, Route } from '@angular/router';
import { AuthService } from '@core/services/users/auth.service';

@Injectable()
export class LoginGuard implements CanActivate {

  permiso: any = {};

  constructor(
    private router: Router,
    private service: AuthService,
  ) {
  }

  canLoad(route: Route): boolean {
    //console.log('route.path: ', route.path);
    const url = `/${route.path}`;

    if (this.service.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  canActivate(): boolean {

    if (this.service.isLoggedIn()) {
      // console.log('de login al /auction o /documentos o /busqueda');
      return true;
    } else {
      // console.log('de login al login');
      this.router.navigate(['/login']);
      return false;
    }
  }
}
