import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

// servicios
import { DashboardService } from '@core/services/resources/dashboard.service';
import { AuthService } from '@core/services/users/auth.service';
import { EncrDecrService } from '@core/services/encr-decr.service';
import { Login } from '@core/interfaces/login';
import { take } from 'rxjs/operators';
import { TimerService } from '@core/services/timer/timer.service';
import { of } from 'rxjs';


const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginform: FormGroup;
  passHide = true;
  referenceCode = '';

  // interfaz
  activeloadingfull = false;

  constructor(
    private router: Router,
    public formB: FormBuilder,
    public snackBar: MatSnackBar,
    public _dashboardService: DashboardService,
    private _authService: AuthService,
    private _encrDecrService: EncrDecrService,
    private _timerService: TimerService,
    private route: ActivatedRoute,
  ) {
    this._timerService.setCloseStatus(3);
    this._dashboardService.setDashboardStatus(false);
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(take(1))
      .subscribe(params => {
        console.log(params)
        this.referenceCode = params.reference_code;
      })
    this.loginform = this.formB.group({
      emailForm: new FormControl('', [
        Validators.required,
        Validators.pattern(EMAIL_REGEX)
      ]),
      passForm: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ])
    });

  }

  onSubmitLogin(loginform: FormGroup): void {
    if (!loginform.valid) {
      this.snackBar.open('El correo/contraseña es incorrecto', 'error', {
        duration: 2000,
      });
    } else {
      if (loginform.value) {
        const formvalue = loginform.value;
        this.activeloadingfull = true;

        const data: Login = {
          email: formvalue.emailForm,
          password: formvalue.passForm
        };

        this._authService.login(data).pipe(take(1))
          .subscribe((res: any) => {
            if (res) {
              const result = this._encrDecrService.getJson(res.value);
              console.log(result);
              if (result.typeUser) {
                let resCompany = {};
                switch ((result.typeUser).toLowerCase()) {
                  case 'natural': this._authService.setUser(result.natural);
                    this._authService.setToken(result.natural.token);
                    break;
                  case 'company': resCompany = {
                    numberDocument: result.company.numberDocument,
                    createAt: result.company.createAt,
                    name: result.company.name
                  };
                    this._authService.setCompany(resCompany);
                    this._authService.setUser(result.company.user);
                    this._authService.setToken(result.company.user.token);
                    break;
                  case 'juridico': resCompany = {
                    numberDocument: result.company.numberDocument,
                    createAt: result.company.createAt,
                    name: result.company.name
                  };
                    this._authService.setCompany(resCompany);
                    this._authService.setUser(result.company.user);
                    this._authService.setToken(result.company.user.token);
                    break;
                  default:
                    break;
                }
                this.router.navigate(['/main/subasta']);
              }
            } else {
              this.snackBar.open('Ocurrio un error durante el Login', 'error', {
                duration: 2000,
              });
            }
          }, (err: HttpErrorResponse) => {
            // let errorDesencriptado = this._encrDecrService.getJson(err.error.value);
            console.log("Error al logear:", err.error)
            this.activeloadingfull = false;
            this.snackBar.open('El correo/contraseña es incorrecto', 'error', {
              duration: 2000,
            });
          });
      }
    }
  }



}
