import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AccountModalComponent } from '../../accounts/modal/account-modal/account-modal.component';

import { Router } from '@angular/router';
import { AuctionService } from '@core/services/auction/auction.service';
import { HttpErrorResponse } from '@angular/common/http';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-modal-auction-leave',
  templateUrl: './modal-auction-leave.component.html',
  styleUrls: ['./modal-auction-leave.component.scss']
})
export class ModalAuctionLeaveComponent implements OnInit {

  dataRecieved: any;
  // status: boolean;
  constructor(
    public dialogRef: MatDialogRef<AccountModalComponent>,
    private router: Router,
    private _auctionService : AuctionService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.dataRecieved = data;
  }

  ngOnInit() {
    // this.bankName = this.dataRecieved.bank.name;
    // this.bankTypeAccount = this.dataRecieved.coin.name;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  close2(){    
    this.dialogRef.close('true');
  }
  close() {
    // this.dialogRef.close(false);
    if (this.dataRecieved){
      let idBankAccount = this.dataRecieved.id;
      let id = this.dataRecieved.id
      let obj = {};
      this._auctionService.setAuctionLeave(obj,id).pipe(take(1))
      .subscribe((res: any) => {
      if (res) {
        }
      }, (err: HttpErrorResponse) => {
        //console.log(err)
      });
      // localStorage.removeItem('tempData');
      this.dialogRef.close('true');
      this.router.navigate(['/main/subasta']);
    }else{
      this.dialogRef.close();
      this.router.navigate(['/main/subasta']);
    }
    
  }

}
