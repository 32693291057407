export const environment = {
  production: true,
  cloudinarySettings: {
    cloud_name: 'firbix',
    upload_preset: 'ml_default'
  },
  aws: {
    accessKeyId: 'AKIAI5SDYNKM2VJNGPUQ',
    secretAccessKey: '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',
    bucket: 'file-firbix'
  },
  urlBackAuth: 'https://prod-back-auth.firbid.com',
  urlBackReset: 'https://prod-back-reset.firbid.com',
  urlBackRegister: 'https://prod-back-register.firbid.com',
  urlBackSunat: 'https://prod-back-security.firbid.com',
  urlBackProfile: 'https://prod-back-profile.firbid.com',
  urlBackAuctionInfo: 'https://prod-back-auction-info.firbid.com',
  urlBackAuctionProcess: 'https://prod-back-auction-process.firbid.com',
  urlBackAuctionPay: 'https://prod-back-auction-pay.firbid.com',
  urlBackAccounts: 'https://prod-back-accounts.firbid.com',
  urlBackInfo: 'https://prod-back-info.firbid.com',
  urlBackRegNatural: 'https://prod-back-reg-natural.firbid.com',
  urlBackRegCompany: 'https://prod-back-reg-company.firbid.com',

  urlSocket: 'https://prod-back-cliente-socket.firbid.com',

  keyCrypto: 'bQeThWmYq3t6w9z$C&F)J@NcRfUjXn2r',
  ONE_SIGNAL_APP_ID: 'c01ef5aa-cb90-4184-b4fd-ae411209dd99',
  ONE_SIGNAL_APP_SAFARI_ID: 'web.onesignal.auto.48d27e8c-5bf0-4f8f-a083-e09c208eb2cb',
};
