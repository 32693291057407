import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-firbid-tooltip',
  templateUrl: './firbid-tooltip.component.html',
  styleUrls: ['./firbid-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("tooltip", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(":leave", [animate(300, style({ opacity: 0 }))])
    ])
  ]
})
export class FirbidTooltipComponent implements OnInit {
  @Input() text = "";
  @Input() img = "";
  constructor() { }

  ngOnInit() {
  }

}
