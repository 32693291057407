import { Injectable } from '@angular/core';
import {PreloadingStrategy, Route} from '@angular/router';
import {Observable, of} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CustomPreloadStrategyService implements PreloadingStrategy{
  
  //Declaramos módulos que pre-cargaremos en el Login
  preloadedModules: string[] = []

  constructor() { }

  preload(route: Route, load: () => Observable<any>): Observable<any> {    
    if(route.data && route.data['preload']){
      this.preloadedModules.push(route.path)
      //Debug
      // console.log("Pre cargar:",route.path)
      return load();
    }else{
      return of(null);
    }
  }
}
