import { Injectable } from '@angular/core';
import { environment } from '@env/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService{

  private oneSignalId = environment.ONE_SIGNAL_APP_ID;
  private oneSignalSafariId = environment.ONE_SIGNAL_APP_SAFARI_ID
  private oneSignal = null;

  constructor(
  ) { }

  wasInit(){
    return Boolean(this.oneSignal);
  }
  
  async onInit() {
    let oneSignal = this.oneSignal;
    if(oneSignal) return oneSignal;
    oneSignal = await this.onLoad()
    oneSignal.init({
      appId: this.oneSignalId,
      safari_web_id: this.oneSignalSafariId,
      notifyButton: {
        enable: false,
        size: 'medium',
        position: 'bottom-right',
        prenotify: true,
        showCredit: false,
        text: {
          'message.prenotify': 'Haz click para recibir notificaciones.',
          'tip.state.subscribed': "Te has suscrito a las notificaciones.",
          'tip.state.blocked': "Las notificaciones han sido bloqueadas.",
          'tip.state.unsubscribed': 'Suscribete para que no se te pierda ninguna operación.',
          'tip.state.resubscribed': 'Te has suscrito a las notificaciones.',
          'message.action.subscribed': "¡Gracias por suscribirte!.",
          'message.action.resubscribed': "Te has suscrito a las notificaciones.",
          'message.action.unsubscribed': "Ya no recibiras ninguna notificación.",
          'dialog.main.title': 'Manage Site Notifications',
          'dialog.main.button.subscribe': 'Activar alertas',
          'dialog.main.button.unsubscribe': 'Bloquear alertas',
        },
        colors: { 
          'circle.background': '#9768FF',
          'circle.foreground': 'white',
          'badge.background': '#9768FF',
          'badge.foreground': 'white',
          'badge.bordercolor': 'white',
          'pulse.color': 'white',
          'dialog.button.background.hovering': '#9768FF',
          'dialog.button.background.active': '#9768FF',
          'dialog.button.background': '#1F1933',
          'dialog.button.foreground': 'white'
        },
        /*displayPredicate: function() {
          return window.OneSignal.isPushNotificationsEnabled()
              .then(function(isPushEnabled) {/
                  return !isPushEnabled;
              });
        },*/
      },
      promptOptions: {
        slidedown: {
          prompts: [
            {
              type: "push", // current types are "push" & "category"
              autoPrompt: false,
              text: {
                /* limited to 90 characters */
                actionMessage: "Nos gustaria enviarte notificaciones para ayudarte a encontrar el mejor tipo de cambio.",
                /* acceptButton limited to 15 characters */
                acceptButton: "Aceptar",
                /* cancelButton limited to 15 characters */
                cancelButton: "Cancelar"
              },
              delay: {
                pageViews: 1,
                timeDelay: 1
              },
              force: true
            }
          ],
          force: true
        },
        force: true
      },
      welcomeNotification: {
        "title": "Bienvenido a Firbid",
        "message": "¡Gracias por suscribirte!.",
      },
      allowLocalhostAsSecureOrigin: true,
      autoRegister: false,
      notificationClickHandlerMatch: 'origin',
      notificationClickHandlerAction: 'navigate',
      persistNotification: false,
    });
    this.oneSignal = oneSignal;
    return oneSignal;
  }

  async onLoad(): Promise<any> {
    window.OneSignal = window.OneSignal || [];
    return new Promise((resolve) => {
      window.OneSignal.push(function() {
        resolve(window.OneSignal);
      });
      window.OneSignal.push(function() {
        window.OneSignal.on('permissionPromptDisplay', function () {
          console.log("The prompt displayed");
        });
      });
    });
  }

  getUserId(): Promise<string>{
    const oneSignal = window.OneSignal || [];
    return new Promise((resolve) => {
      oneSignal.push(['getUserId', function(userId){
        resolve(userId)
      }])
    })
    /*window.OneSignal = window.OneSignal || [];
    return new Promise((resolve) => {
      window.OneSignal.push(function(){
        window.OneSignal.getUserId()
          .then(resolve)  
      })
    })*/
  }

  showNativePrompt(){
    const oneSignal = window.OneSignal || [];
    oneSignal.push(['showNativePrompt'])
  }

  registerForNotifications(){
    window.OneSignal.push(function () {
      console.log('Register For Push');
      window.OneSignal.push(["registerForPushNotifications"])
    });
  }

  getState(cb): void{
    window.OneSignal = window.OneSignal || [];
    window.OneSignal.push(['getNotificationPermission', function(permission){
      console.log('Permision state: ', permission)
      cb(permission)
    }])
    window.OneSignal.push(function(){
      window.OneSignal.on('notificationPermissionChange', function(permissionChange) {
        var currentPermission = permissionChange.to;
        console.log('New permission state:', currentPermission);
        cb(currentPermission)
      });
    })
  }

  getSubscriptionState(cb): void{
    window.OneSignal.push(function(){
      window.OneSignal.isPushNotificationsEnabled(function(isEnabled) {
        cb(isEnabled) 
      });
    })
    window.OneSignal.push(function(){
      window.OneSignal.on('subscriptionChange', function (isSubscribed) {
        console.log("The user's subscription state is now:", isSubscribed);
        cb(isSubscribed)
      });
    })
  }

  isPushNotificationEnabled(): Promise<boolean>{
    return new Promise((res) =>{
      window.OneSignal.push(function(){
        window.OneSignal.isPushNotificationsEnabled(function(isEnabled) {
          if (isEnabled)
            console.log("Push notifications are enabled!");
          else
            console.log("Push notifications are not enabled yet.");
          return res(isEnabled)   
        });
      })
    })
  }

  listenNotificationOpened(){
    window.OneSignal.push(["addListenerForNotificationOpened", function(data) {
      console.log("Received NotificationOpened:");
      console.log(data);
    }]);
  }
  
  cleanSubscribe(){
    window.OneSignal.push(["setSubscription", false]);
  }

  activeSubscribe(){
    window.OneSignal.push(["setSubscription", true]);
  }

  showPrompt(){
    console.log("Show prompt")
    window.OneSignal.push(function() {
      window.OneSignal.showSlidedownPrompt({ force: true });
    });
  }

}
